.galerija-container{
    width: 100%;
    height: auto;
    background-image: url('../../slike/patern.png');
    padding-top: 10px;
    overflow: hidden;
}


.galerija-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

}

.galerija-wrapper img {
    max-width: 300px;
    cursor: pointer;
    margin: 0 auto;
    
}



.galerija-slika-wrapper{
    height: auto;
    background-color: white;
    padding-bottom: 10px;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all ease-out 0.3s;
}
.galerija-slika-wrapper:hover {
    transform: scale(1.1);
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    display: flex;
    align-items: center;
  }

  .overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  }

  .overlay > span {
    position: absolute;
    top: 60px;
    right: 40px;
    font-size: 30px;
    color: #ffffff;
    z-index: 1000;
    cursor: pointer;
  }
  .slika-tekst{
    padding: 5px;
    max-width: 300px;
    font-size: 1em;
    font-weight: normal;
  }

  @media only screen and (max-width:1000px){

    .galerija-container img{
        max-width: 500px;
        width: 99%;
    }

    .galerija-slika-wrapper {
        max-width: 500px;
        width: 95%;
    }
}