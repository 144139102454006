.pozadina {
    width: 100%;
    padding: 20px;
    height: auto;
    background-image: url('../../slike/patern.png');
}

.izvor{
    max-width: 1000px;
    width: auto;
    height: auto;
    margin: 50px auto;
    font-size: 1.05rem;
    list-style-position: inside;
}

@media only screen and (max-width:  480px) {

    
    
    .pozadina{
        padding: 10px;
    }

   
    

}