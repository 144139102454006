* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  flex-direction: column;
  gap: 1rem;
  background: #BB834E;
  color: white;
}

.flex-container h1 {
  font-size: 2rem;
}

.flex-container a {
  color: white;
}

@media screen and (min-width: 700px) {
  .flex-container h1 {
    font-size: 4rem;
  }
}



