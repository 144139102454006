.nav-line{
    width: 100%;
    height: 60px;
    background-color: #BB834E;
}
.about-naslov{
    color: #BB834E;
    padding: 20px;
}
.about-container{
    width: 100%;
    height: auto;
    background-color: gray;
    box-sizing: border-box;
    background-image: url('../../slike/patern.png');
    
}
.about-wrapper{
    background-image: linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7));
    max-width: 800px;
    width: 95%;
    height: auto;
    font-size: 1.15rem;
    color: #676767;
    margin: 0 auto;
    padding: 0 30px 30px 30px;
    border-left: 3px solid #BB834E;
    border-right: 3px solid #BB834E;
}

.about-lista{
    list-style: none;
    text-align: center;
}
.about-lista-element{
 padding: 0px;   
 
 
}

.about-podlista{
    text-align: left;
    list-style-position: inside;
}

.about-image{ 
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
}

.grbovi{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    margin-left: 0;
    list-style: none;
}

.onama-video{
    width: 100%;
    height: 400px;
    background-color: black;
}
