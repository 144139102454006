.carousel-container{
    height: 700px;
    width: 100%;
    position: relative;
    
}

.carousel-element{
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
    position: absolute;
    z-index: -1;
}

.active-anim {
    opacity: 1;
  }

.carousel-element-slika{
    width: 100%;
    height: 100%;
	background-size: cover;
	background-position: center;
    
}
.video {
    object-fit: cover;
    object-position: center;
}

.carousel-element-btn{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: none;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    transition: color ease-in-out 0.4s;
    transition: background-color ease-in-out 0.2s;
}

.carousel-element-btn:hover{
    color:#BB834E;
    background-color: white;
}


.leva {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .desna {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }

  .up {
    display: none;
    left: 50%;
    top: 60px;
    transform: translateX(-60%);
  }
  .down {
    display: none;
    left: 50%;
    bottom: 60px;
    transform: translateX(-60%);
  }



  .dot-container {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    
  }
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
    cursor: pointer;
  }
  .dot.active {
    background: #BB834E;
  }
  .dimuseLogo{
    width: 130px;
    height: auto;
}

.dimuse-naslov{
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 50px;
    text-align: center;
}

.carousel-naslov-wrapper{
    height: calc(100% - 60px);
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.carousel-naslov{
    font-size: 2.25rem;
    text-transform: uppercase;
    text-align: center;
}

.carousel-podnaslov{
    font-size: 1.75rem;
    text-transform: none;
    text-align: center;
}

.obilazak {
    vertical-align: middle;
    display: inline-block;
    width: auto;
    height: 50px;
    margin-top: 30px;
    padding: 5px 10px;
    background-color: #BB834E;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    border: solid;
    border-width: 2px;
    border-color: #BB834E;
    text-transform: uppercase;
    font-weight: bold;
    transition: all ease-in-out 0.3s;
    
}

.obilazak:hover {
    background-color: white;
    color: #BB834E;
    
}

@media only screen and (max-width:  480px) {

    .dimuse-naslov{
        padding: 0 15px;
    }
    .carousel-naslov{
        font-size: 2rem;
    }
    .carousel-podnaslov{
        padding: 0 15px;
        font-size: 1.5rem;
    }
    .sadrzaj-container {
        padding: 10px;
    }


}
