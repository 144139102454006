.naslovna-container {
    width: 100%;
    height: 700px;
    background-size: cover;
    background-position: center top;
}

.naslov-wrapper {
    display: table;
    height: calc(100% - 60px);
    width: 100%;
    margin: 0;
    text-align: center;
}

.naslov {
    color: #BB834E;
    font-size: 2.25rem;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 20px;
}


.naslov-wrapper .naslov{
    display: table-cell;
    vertical-align: middle;
    font-size: 2.5rem;
    color: white;
}

.pnaslov{
    font-size: 1.5rem;  
}

.naslovna-menu {
    width: 100%;
    min-height: 60px;
    height: auto;
    background-color: rgb(183, 156, 131);
    color: white;
    position: relative;
    display: block;
}

.sec-nav{
    max-width: 700px;
    width: 100%;
    min-height: 60px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.sec-nav-dugme{
    min-width: 100px;
    display: inline-block;
    width: auto;
    height: 40px;
    position: relative;
    margin:10px 0;
    padding: 8px;
    color: white;
    font-weight: bold;
    background-color: #BB834E;
    font-size: 1rem;
    text-align: center;
    border-right: 3px solid #91663d;
    cursor: pointer;
    transition: all ease-out 0.2s;
}

.sec-nav-dugme:hover {
    background-color: white;
    color: #BB834E;
}
audio{
    height: 40px;
    margin:10px auto;
}

@media only screen and (max-width:  480px) {

    
    
    .naslov{
        font-size: 1.5rem;
    }

    .naslov-wrapper .naslov{
        font-size: 2rem;
    }
    .pnaslov{
        font-weight: normal;
        text-transform: initial;  
    }
    

}



