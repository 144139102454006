.footer-container {
    min-height: 300px;
    height: auto;
    background-color: #BB834E;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.footer-element-wrapper {
    max-width: 500px;
    width: 100%;
    color: white;
}

.mapa-wrapper{
    background-image: linear-gradient(to right, rgba(255,220,187,0.9) 25%, rgba(100,100,100,0.6)),url(../../slike/lokacija.png);
    padding-left: 10px;
    padding-right: 10px;
}



.form-input-field{
    width: 100%;
    height: 40px;
    font-size: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
}

.form-text-area{
    width: 100%;
    height: 250px;
    border: none;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.form-button {
    width: 100%;
    height: 40px;
    background-color: #BB834E;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.form-button:hover{
    background-color: white;
    color:#BB834E;
    border-color: rgba(187,131,78,1);
    border-style: solid;
    border-width: 2px;
}

.mapa-wrapper .footer-element-wrapper{
    color: rgba(187,131,78,1);;
    padding: 0;
    margin: 15px auto;
    min-height: 420px;
    border: none;
}

.mapa {
    max-width: 700px;
    width: 100%;
    height: 420px;
}

.grb {
    width: 250px;
    height: auto;
    display: block;  
    margin-bottom: 30px;  
}

.grb-slika {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;  
}

.grb-tekst {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: white;
    text-align: center;
    font-weight: bold;

}

