.sadrzaj-container {
    
    width: 100%;
    margin: 0 auto 30px auto;
    max-width: 1200px;
    display: flex;

    align-items: center;
    background-image: linear-gradient(to right, rgba(255, 255,255,0.4) 30%,rgba(255, 255,255,0.4));
    justify-content: left;
    padding: 30px 10px;
}

.sadrzaj-container-slika{
    width: 100%;
    height: auto;
    margin: 10px;
}

.tip2 {
    background-image: linear-gradient(to right, rgba(255, 220,188,1) 30%,rgba(255, 255,255,0));
    padding: 30px 15px;
}

.sadrzaj-tekst-wrapper {
    width: auto;
    max-width: 659px;
    
    height: auto;
    color: #676767;
  
    position: relative;
}

.sadrzaj-tekst {
    padding: 0 15px;
    line-height: 2rem;
    margin-bottom: 100px;
    font-size: 1.4rem;
    text-align: justify;
}
.sadrzaj-slika {
    width: auto;
    min-width: 300px;
    height: auto;
    max-height: 440px;
    margin: 0;
    padding: 0 15px;
}

.sadrzaj-dugme {
    position: absolute;
    text-align: center;
    line-height: 2.25em;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 200px;
    height: 50px;
    font-weight: bolder;
    display:block;
    color: white;
    background-color: rgba(187,131,78,1);
    font-size: 1.25rem;
    text-transform: uppercase;
    margin: 0 auto;
    cursor: pointer;
    border: none;
    text-decoration: none;
}

.sadrzaj-dugme:hover {
    background-color: white;
    color: rgba(187,131,78,1);
    border-color: rgba(187,131,78,1);
    border-style: solid;
    border-width: 2px;
}

.tip3 {
    max-width: 1300px;
    background-image: linear-gradient(to right, rgba(255, 220,188,1) 30%,rgba(255, 255,255,0));
    margin: 0 auto;
    
}

.tip3-tekst, .tip4-tekst{
    line-height: 1.5rem;
    font-size: 1.25rem;
    max-width: 700px;
    min-height: unset;
    margin: 0;
    width: auto;
}

.tip4-tekst{
    padding: 15px;
}

.tip3-tekst{
    padding: 0 10px;
}

.tip3-slika {
    max-width: 600px;
    width: auto;
    max-height: 500px;
    height: auto;
    padding: 0 15px;
}

.border {
    border-top: 2px solid #BB834E;
    border-bottom: 2px solid #BB834E;
}
.tip4-tekst{
    
   max-width: 1300px;
   margin: 0 auto;
}

.align{
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width:1000px){

    .sadrzaj-container{
        justify-content: center;
        flex-wrap: wrap;
    }
    .sadrzaj-slika{
        padding: 0;
        max-width: unset;
        width: 100%;
        max-height: unset;
        height: auto;
    }

    .tip3-tekst{
        padding-left: 0;
        padding-right: 0;
    }
    .tip4-tekst{
        padding-left: 10px;
        padding-right: 10px;
    }
    .sadrzaj-tekst-wrapper{
        max-width: none;
    }
    .galerija-container img{
        max-width: 500px;
        width: 95%;
    }

    .tip3-slika {
        max-width:none;
        width: 100%;
        height: auto;
    }
    

}
@media only screen and (max-width:  480px) {

    
    .sadrzaj-container {
        padding: 10px;
    }
    .naslov{
        font-size: 2rem;
    }
    .sadrzaj-tekst{
        font-size: 1.1rem;
        line-height: 1.3rem;
        padding:0;
    }
    .tip4-tekst{
        padding-left: 0;
        padding-right: 0;
    }

}
