.navigation-container {
    width: 100%;
    height: 60px;
    position: absolute;
    z-index: 500;
}

nav {
    min-width: 100px;
    max-width: 850px;
    height: 60px;
    width: auto;
    display: inline-block;
}
.nav-icon{
    display: none;
}

nav ul {
    margin: 0 0 0 20px;
    position: relative;
    padding: 0;
    height: 60px;
}

nav ul li {
    font-size: 1rem;
    color: white;
    position: relative;
    font-weight: bold;
    list-style: none;
    display: inline-block;
    line-height: 60px;
    text-transform: uppercase;
    margin-left: 20px;
    cursor: pointer;
}

nav ul li::after {
    position:absolute;
    bottom: 18px;
    left: 0;
    content: '';
    height: 2px;
    width: 100%;
    background-color: #FFDCBB;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

nav ul li:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
   
}



nav ul li:hover {
    color: #FFDCBB;
    
}

nav ul ul
{
	background-color: white;
	position: absolute;
	top: 45px;
	list-style: none;
	font-size: inherit;
	font-weight: inherit;
    height: auto;
    max-height: 0;
    min-width: 100%;
	margin:0;
    overflow: hidden;
    transition:max-height 0.5s;
}

nav ul ul li
{
	line-height: normal;
    font-size: 12px;
    padding: 5px;
    color:rgba(187,131,78,1);
	margin: 0;
	float: none;
	display: list-item;
	position: relative;	
    
}

.link {
    text-decoration: none;
}

nav ul ul li::after {
    content: none;
}

nav ul li:hover > ul
{
	max-height: 150px;
    height: auto;
}

nav ul ul li:hover {
    background-color:rgb(235, 235, 235);
    color:rgba(187,131,78,1);
}


@media only screen and (max-width: 900px) {

    .desktopnav{
        display: none;
    }
    nav ul li:hover::after{
        content: none;
    }
    .navigation-container {
        background-color: #BB834E;
    }
    nav{
        position: relative;
        width: 50px;
    }

    nav ul{
       position: absolute;
       margin: 0;
       top:60px;
       height: auto;
       width: 200px;
       background-color: #BB834E;
       

    }
    nav ul li{
        width: 100%;
        margin: 0 auto;
        text-align: center;
        line-height: 50px;
        height: 50px;
        padding: 0 20px;
    }
    
    nav ul ul{
        left: 200px;
        top:-50px;
    }
    nav ul ul li {
        line-height: 40px;
    }
    .nav-icon
    {
        display: inline-block;
        margin: 15px 15px;
        color: white;
    }

    .carousel-element-btn{
        display: none;
    }

    .up, .down {
        display: block;
    }
    .link{
        line-height: 40px;
     }
     nav ul li a{
        line-height: 40px;
     }
 
}

@media only screen and (max-width:  480px) {

    nav ul{
        width:130px;
     }
     nav ul li{
         font-size: 12px;
     }
     nav ul ul{
         left: 130px;
         top:-40px;
     }
     .nav-icon
     {
         margin: 15px 15px;
     }
     


    

}